import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import SecureRoute from "../../services/secureRoute";
import DashboardContainer from "../Dashboard/DashboardContainer";
import LoginContainer from "../Login/LoginContainer";
import Navigation from "../Navigation/Navigation";
import NotFound from "../NotFound/NotFound";
import NewPatientContainer from "../Patients/NewPatientContainer";
import PatientsContainer from "../Patients/PatientsContainer";
import AppointmentsContainer from "../Appointments/AppointmentsContainer";
import NewAppointmentContainer from "../Appointments/NewAppointmentContainer";
import PatientEditContainer from "../Patients/PatientEditContainer";
import AppointmentEditContainer from "../Appointments/AppointmentEditContainer";
import { useEffect, useState } from "react";

function App() {
  let width = window.innerWidth;
  let height = window.innerHeight;
  const [error, setError] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      width = window.innerWidth;
      height = window.innerHeight;
      console.log(width, height);

      if (height < 600 || width < 820) {
        setError(true);
      } else {
        setError(false);
      }
    });
  }, [height, width, error]);
  return (
    <div className="App">
      {!error && <Navigation />}
      {!error ? (
        <Routes>
          <Route
            path="/dashboard"
            element={
              <SecureRoute>
                <DashboardContainer />
              </SecureRoute>
            }
          />
          <Route path="/patients" element={<PatientsContainer />} />
          <Route
            path="/patients/newPatient"
            element={
              <SecureRoute>
                <NewPatientContainer />
              </SecureRoute>
            }
          />
          <Route
            path="/patients/:id/edit"
            element={
              <SecureRoute>
                <PatientEditContainer />
              </SecureRoute>
            }
          />
          <Route
            path="/patients/:id/appointments"
            element={
              <SecureRoute>
                <AppointmentsContainer />
              </SecureRoute>
            }
          />
          <Route
            path="/patients/:id/appointments/new"
            element={
              <SecureRoute>
                <NewAppointmentContainer />
              </SecureRoute>
            }
          />
          <Route
            path="/patients/:id/appointments/:idAppointment/edit"
            element={
              <SecureRoute>
                <AppointmentEditContainer />
              </SecureRoute>
            }
          />

          <Route path="/login" element={<LoginContainer />} />
          <Route exact path="/" element={<Navigate to="/dashboard" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <h1>Aplikacja nie jest przystosowana pod urządzenia mobilne!</h1>
      )}
    </div>
  );
}

export default App;
