import styled, { css } from "styled-components";
import Button from "../Button/Button";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  transition: 0.3s;
  -webkit-box-shadow: 2px 2px 11px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 11px -5px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 11px -5px rgba(66, 68, 90, 1);
  overflow: hidden;
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    &.controls {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    &.info {
      width: 60%;
      flex-direction: column;
      text-align: left;
      & h3 {
        & span {
          font-weight: 400;
        }
      }
    }
    &.appointment-title-card {
      justify-content: space-around;
      background: rgb(213, 119, 181);
      width: 100%;
      color: white;
    }
    &.appointment-details {
      width: 90%;
      text-align: justify;
    }
    &.appointment-buttons {
      width: 50%;
      justify-content: space-around;
      margin: 2rem 0;
    }
    &.pictures {
      width: 80%;
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 2rem 0;
      & div {
        width: 30%;
        max-height: 20vh;
        object-fit: cover;
        overflow: hidden;
        & img {
          width: 90%;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            width: 100%;
          }
        }
      }
    }
  }
  ${(props) =>
    props.margin &&
    css`
      margin-bottom: 2rem;
    `}
  ${(props) =>
    props.appointment &&
    css`
      flex-direction: column;
      margin-bottom: 2rem;
    `}
  &:hover {
    border-color: rgb(213, 119, 181);
    transform: scale(1.01);
  }
`;

const Card = (props) => {
  return (
    <StyledContainer margin={props.margin} appointment={props.appointment}>
      {!props.appointment ? (
        <>
          <div className="info">
            <h1>
              {props.name} {props.surname}
            </h1>
            <h3>
              E-mail: <span>{props.email}</span>
            </h3>
            <h3>
              Numer telefonu: <span>{props.phone_number}</span>
            </h3>
          </div>
          <div className="controls">
            <Button text="Edytuj" onClick={props.editPatient} />
            <Button text="Wizyty" onClick={props.allAppointmentsRedirect} />
            <Button text="Usuń" decline onClick={props.deletePatient} />
          </div>
        </>
      ) : (
        <>
          <div className="appointment-title-card">
            <h2>{props.title}</h2>
            <h3>Data: {props.date}</h3>
          </div>
          <div className="appointment-details">
            <p>{props.description}</p>
          </div>
          {props.children}
          <div className="appointment-buttons">
            <Button text="Edytuj" onClick={props.editAppointment} />
            <Button text="Usuń" decline onClick={props.deleteAppointment} />
          </div>
        </>
      )}
    </StyledContainer>
  );
};
export default Card;
