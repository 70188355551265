import NewPatient from "./NewPatient";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useInput from "../../hooks/use-input";
import { addNewPatient } from "../../services/api";
import Modal from "../shared/Modal/Modal";

const NewPatientContainer = () => {
  const navigate = useNavigate();
  const [addPatientError, setAddPatientError] = useState(false);
  const [addingPatient, setAddingPatient] = useState(false);
  const [message, setMessage] = useState(null);
  const emailRegex = /\S+@\S+\.\S+/;
  const phoneRegex = /(?<!\w)\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/;
  const {
    inputValue: nameValue,
    isTouched: nameIsTouched,
    error: nameError,
    inputValueHandler: nameValueHandler,
    inputBlurHandler: nameBlurHandler,
    reset: nameReset,
  } = useInput((input) => input.trim().length > 0);

  const {
    inputValue: surnameValue,
    isTouched: surnameIsTouched,
    error: surnameError,
    inputValueHandler: surnameValueHandler,
    inputBlurHandler: surnameBlurHandler,
    reset: surnameReset,
  } = useInput((input) => input.trim().length > 0);

  const {
    inputValue: emailValue,
    isTouched: emailIsTouched,
    error: emailError,
    inputValueHandler: emailValueHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput((input) => input.trim().length > 0 && emailRegex.test(input));

  const {
    inputValue: phoneValue,
    isTouched: phoneIsTouched,
    error: phoneError,
    inputValueHandler: phoneValueHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: phoneReset,
  } = useInput((input) => input.trim().length > 9 && phoneRegex.test(input));

  const declineHandler = () => {
    navigate("/dashboard");
  };

  let disabled = true;
  if (
    nameError ||
    !nameIsTouched ||
    surnameError ||
    !surnameIsTouched ||
    emailError ||
    !emailIsTouched ||
    phoneError ||
    !phoneIsTouched
  ) {
    disabled = true;
  } else {
    disabled = false;
  }

  const newPatientHandler = async (evt) => {
    evt.preventDefault();
    setAddingPatient(true);
    try {
      const data = await addNewPatient({
        email: emailValue,
        name: nameValue,
        surname: surnameValue,
        phone_number: phoneValue,
      });
      if (data.error) {
        setAddPatientError(true);
        setAddingPatient(false);
      } else {
        setTimeout(() => {
          setAddingPatient(false);
        }, 2000);
        setAddPatientError(false);
        setMessage("Pomyślnie dodano nowego pacjenta!");
        nameReset();
        surnameReset();
        emailReset();
        phoneReset();
      }
    } catch (err) {
      setAddPatientError(true);
      setAddingPatient(false);
      setMessage(err.message);
    }
  };

  return (
    <>
      {addingPatient ? (
        <Modal animate title="Dodawanie pacjenta" />
      ) : (
        <NewPatient
          declineHandler={declineHandler}
          nameValue={nameValue}
          nameIsTouched={nameIsTouched}
          nameError={nameError}
          nameBlurHandler={nameBlurHandler}
          nameValueHandler={nameValueHandler}
          surnameValue={surnameValue}
          surnameIsTouched={surnameIsTouched}
          surnameError={surnameError}
          surnameBlurHandler={surnameBlurHandler}
          surnameValueHandler={surnameValueHandler}
          emailValue={emailValue}
          emailIsTouched={emailIsTouched}
          emailError={emailError}
          emailBlurHandler={emailBlurHandler}
          emailValueHandler={emailValueHandler}
          phoneValue={phoneValue}
          phoneIsTouched={phoneIsTouched}
          phoneError={phoneError}
          phoneBlurHandler={phoneBlurHandler}
          phoneValueHandler={phoneValueHandler}
          disabled={disabled}
          onSubmit={newPatientHandler}
          addPatientError={addPatientError}
          addingPatient={addingPatient}
          message={message}
        />
      )}
    </>
  );
};
export default NewPatientContainer;
