import PatientEdit from "./PatientEdit";
import { useParams } from "react-router-dom";
import { getPatient } from "../../services/api";
import { useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import { useNavigate } from "react-router-dom";
import { editPatient } from "../../services/api";
import Modal from "../shared/Modal/Modal";

const PatientEditContainer = () => {
  const navigate = useNavigate();
  const [patient, setPatient] = useState(null);
  const [editingPatient, setEditingPatient] = useState(false);
  const [editingPatientError, setEditingPatientError] = useState(false);
  const [message, setMessage] = useState();
  const emailRegex = /\S+@\S+\.\S+/;
  const phoneRegex = /(?<!\w)\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/;
  let { id } = useParams();

  const {
    inputValue: nameValue,
    isTouched: nameIsTouched,
    error: nameError,
    inputValueHandler: nameValueHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput(
    (input) => input.trim().length > 0,
    false,
    patient?.data.attributes.name
  );

  const {
    inputValue: surnameValue,
    isTouched: surnameIsTouched,
    error: surnameError,
    inputValueHandler: surnameValueHandler,
    inputBlurHandler: surnameBlurHandler,
  } = useInput(
    (input) => input.trim().length > 0,
    false,
    patient?.data.attributes.surname
  );

  const {
    inputValue: emailValue,
    isTouched: emailIsTouched,
    error: emailError,
    inputValueHandler: emailValueHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(
    (input) => input.trim().length > 0 && emailRegex.test(input),
    false,
    patient?.data.attributes.email
  );

  const {
    inputValue: phoneValue,
    isTouched: phoneIsTouched,
    error: phoneError,
    inputValueHandler: phoneValueHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput(
    (input) => input.trim().length > 9 && phoneRegex.test(input),
    false,
    patient?.data.attributes.phone_number
  );

  let disabled = true;
  if (nameError || surnameError || emailError || phoneError) {
    disabled = true;
  } else {
    disabled = false;
  }

  useEffect(() => {
    try {
      const patient = async () => {
        let patientData = await getPatient(id);
        if (patientData.data !== null) {
          setPatient(patientData);
        } else {
          throw new Error("Nie udało się pobrać danych pacjenta!");
        }
      };
      patient();
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  const declineHandler = () => {
    navigate("/patients");
  };

  const editPatientHandler = async (evt) => {
    evt.preventDefault();
    setEditingPatient(true);
    try {
      const data = await editPatient(
        {
          email: emailValue,
          name: nameValue,
          surname: surnameValue,
          phone_number: phoneValue,
        },
        patient?.data.id
      );
      if (data.error) {
        setEditingPatientError(true);
        setEditingPatient(false);
      } else {
        setTimeout(() => {
          setEditingPatient(false);
        }, 2000);
        setEditingPatientError(false);
        setMessage("Pomyślnie zmieniono dane pacjenta!");
      }
    } catch (err) {
      setEditingPatientError(true);
      setEditingPatient(false);
      setMessage(err.message);
    }
  };
  return (
    <>
      {editingPatient ? (
        <Modal animate title="Edycja danych pacjenta" />
      ) : (
        <>
          {patient && (
            <PatientEdit
              nameValue={nameValue}
              nameIsTouched={nameIsTouched}
              nameError={nameError}
              nameBlurHandler={nameBlurHandler}
              nameValueHandler={nameValueHandler}
              surnameValue={surnameValue}
              surnameIsTouched={surnameIsTouched}
              surnameError={surnameError}
              surnameBlurHandler={surnameBlurHandler}
              surnameValueHandler={surnameValueHandler}
              emailValue={emailValue}
              emailIsTouched={emailIsTouched}
              emailError={emailError}
              emailBlurHandler={emailBlurHandler}
              emailValueHandler={emailValueHandler}
              phoneValue={phoneValue}
              phoneIsTouched={phoneIsTouched}
              phoneError={phoneError}
              phoneBlurHandler={phoneBlurHandler}
              phoneValueHandler={phoneValueHandler}
              disabled={disabled}
              declineHandler={declineHandler}
              message={message}
              editingPatientError={editingPatientError}
              editingPatient={editPatient}
              editPatientHandler={editPatientHandler}
            />
          )}
        </>
      )}
    </>
  );
};
export default PatientEditContainer;
