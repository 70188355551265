import { useNavigate } from "react-router-dom";
import Login from "./Login";
import Modal from "../shared/Modal/Modal";
import useInput from "../../hooks/use-input";
import { login } from "../../services/api";
import { useState, useContext } from "react";
import { PodologStoreCtx } from "../../store/store";

const LoginContainer = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(PodologStoreCtx);
  const [errorLogin, setErrorLogin] = useState(false);
  const [logining, setLogining] = useState(false);
  const emailRegex = /\S+@\S+\.\S+/;
  let disabled = true;
  const {
    inputValue: emailValue,
    isTouched: emailIsTouched,
    error: emailError,
    inputValueHandler: emailValueHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((input) => input.trim().length > 0 && emailRegex.test(input));
  const {
    inputValue: passwordValue,
    isTouched: passwordIsTouched,
    error: passwordError,
    inputValueHandler: passwordValueHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((input) => input !== "" && input.trim().length > 5);

  const submitFormHandler = async (evt) => {
    evt.preventDefault();
    setLogining(true);
    try {
      const data = await login({
        email: emailValue,
        password: passwordValue,
      });
      if (!data.error) {
        dispatch({ type: "login", payload: data });
        navigate("/dashboard");
      } else {
        setErrorLogin(true);
        setLogining(false);
      }
    } catch (err) {
      setErrorLogin(true);
      setLogining(false);
    }
  };

  if (emailError || passwordError || !emailIsTouched || !passwordIsTouched) {
    disabled = true;
  } else {
    disabled = false;
  }
  return (
    <>
      {logining ? (
        <Modal title="Logowanie" animate />
      ) : (
        <Login
          submitFormHandler={submitFormHandler}
          emailValue={emailValue}
          emailIsTouched={emailIsTouched}
          emailError={emailError}
          emailBlurHandler={emailBlurHandler}
          emailValueHandler={emailValueHandler}
          passwordValue={passwordValue}
          passwordIsTouched={passwordIsTouched}
          passwordError={passwordError}
          passwordBlurHandler={passwordBlurHandler}
          passwordValueHandler={passwordValueHandler}
          disabled={disabled}
          errorLogin={errorLogin}
        />
      )}
    </>
  );
};
export default LoginContainer;
