import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../shared/Button/Button";
import { PodologStoreCtx } from "../../store/store";
import { useContext } from "react";
import ActiveLink from "../shared/ActiveLink/ActiveLink";

const StyledHeader = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5rem;
  box-sizing: border-box;
  background: rgb(213, 119, 181);
  & div.links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
  }
  & h1 {
    width: 30%;
    color: white;
  }
`;

const Navigation = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(PodologStoreCtx);
  const logoutHandler = () => {
    dispatch({ type: "logout" });
    navigate("/login");
  };
  return (
    <StyledHeader>
      <h1>PODOLOGIA</h1>
      <div className="links">
        <ActiveLink to={"/dashboard"}>Strona główna </ActiveLink>
        <ActiveLink to={"/patients"}>Pacjenci </ActiveLink>
        {localStorage.getItem("isLogged") ? (
          <Button text="Wyloguj się" onClick={logoutHandler} />
        ) : (
          <ActiveLink to={"/login"}>Zaloguj się </ActiveLink>
        )}
        {state.credentials.user && (
          <p>Zalogowany/a jako: {state.credentials.user.email}</p>
        )}
      </div>
    </StyledHeader>
  );
};
export default Navigation;
