import AppointmentEdit from "./AppointmentEdit";
import useInput from "../../hooks/use-input";
import { useEffect, useState } from "react";
import Modal from "../shared/Modal/Modal";
import { useParams } from "react-router-dom";
import { getAppointment, editAppointment } from "../../services/api";
import { useNavigate } from "react-router-dom";

const AppointmentEditContainer = () => {
  const [editingAppointment, setEditingAppointment] = useState(false);
  const [editingAppointmentError, setEditingAppointmentError] = useState(false);
  const [message, setMessage] = useState();
  const [appointment, setAppointment] = useState(null);
  const { idAppointment } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const request = async () => {
        const appointmentResponse = await getAppointment(idAppointment);
        setAppointment(appointmentResponse);
      };
      request();
    } catch (err) {
      console.log(err);
    }
  }, [idAppointment]);

  const {
    inputValue: titleValue,
    isTouched: titleIsTouched,
    error: titleError,
    inputValueHandler: titleValueHandler,
    inputBlurHandler: titleBlurHandler,
  } = useInput(
    (input) => input.trim().length > 0,
    false,
    appointment?.data.attributes.title
  );

  const {
    inputValue: descrValue,
    isTouched: descrIsTouched,
    error: descrError,
    inputValueHandler: descrValueHandler,
    inputBlurHandler: descrBlurHandler,
  } = useInput(
    (input) => input.trim().length > 0,
    false,
    appointment?.data.attributes.description
  );

  const {
    inputValue: dateValue,
    isTouched: dateIsTouched,
    error: dateError,
    inputValueHandler: dateValueHandler,
    inputBlurHandler: dateBlurHandler,
  } = useInput(
    (input) => input.trim().length > 0,
    false,
    appointment?.data.attributes.date.slice(0, 16)
  );

  const editAppointmentHandler = async (evt) => {
    evt.preventDefault();
    setEditingAppointment(true);
    const appointmentData = {
      title: titleValue,
      description: descrValue,
      date: dateValue,
    };
    try {
      const response = await editAppointment(idAppointment, appointmentData);
      if (response.error) {
        setEditingAppointmentError(true);
        setEditingAppointment(false);
      } else {
        setTimeout(() => {
          setEditingAppointment(false);
        }, 2000);
        setEditingAppointmentError(false);
        setMessage("Pomyślnie zmieniono dane wizyty!");
      }
    } catch (err) {
      setEditingAppointmentError(true);
      setEditingAppointment(false);
      setMessage(err.message);
    }
  };

  const editAppointmentDecline = () => {
    navigate(`/patients/${id}/appointments`);
  };
  return (
    <>
      {editingAppointment ? (
        <Modal title="Wprowadzanie zmian" animate />
      ) : (
        <AppointmentEdit
          titleValue={titleValue}
          titleIsTouched={titleIsTouched}
          titleError={titleError}
          titleBlurHandler={titleBlurHandler}
          titleValueHandler={titleValueHandler}
          descrValue={descrValue}
          descrIsTouched={descrIsTouched}
          descrError={descrError}
          descrBlurHandler={descrBlurHandler}
          descrValueHandler={descrValueHandler}
          dateValue={dateValue}
          dateIsTouched={dateIsTouched}
          dateError={dateError}
          dateBlurHandler={dateBlurHandler}
          dateValueHandler={dateValueHandler}
          editAppointmentHandler={editAppointmentHandler}
          editingAppointmentError={editingAppointmentError}
          message={message}
          editAppointmentDecline={editAppointmentDecline}
        />
      )}
    </>
  );
};
export default AppointmentEditContainer;
