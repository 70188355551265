import NewAppointment from "./NewAppointment";
import useInput from "../../hooks/use-input";
import { useState } from "react";
import Modal from "../shared/Modal/Modal";
import {
  uploadImages,
  createNewAppointment,
  addAppointmentToUser,
} from "../../services/api";
import { useContext } from "react";
import { PodologStoreCtx } from "../../store/store";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const NewAppointmentContainer = () => {
  const { state } = useContext(PodologStoreCtx);
  const [addingAppointment, setAddingAppointment] = useState(false);
  const [addingAppointmentError, setAddingAppointmentError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    inputValue: titleValue,
    isTouched: titleIsTouched,
    error: titleError,
    inputValueHandler: titleValueHandler,
    inputBlurHandler: titleBlurHandler,
  } = useInput((input) => input.trim().length > 0);

  const {
    inputValue: descrValue,
    isTouched: descrIsTouched,
    error: descrError,
    inputValueHandler: descrValueHandler,
    inputBlurHandler: descrBlurHandler,
  } = useInput((input) => input.trim().length > 0);

  const {
    inputValue: dateValue,
    isTouched: dateIsTouched,
    error: dateError,
    inputValueHandler: dateValueHandler,
    inputBlurHandler: dateBlurHandler,
  } = useInput((input) => input.trim().length > 0);

  const {
    inputValue: picturesValue,
    isTouched: picturesIsTouched,
    error: picturesError,
    inputValueHandler: picturesValueHandler,
    inputBlurHandler: picturesBlurHandler,
  } = useInput((input) => input.length > 0, true);

  const newAppointmentHandler = async (evt) => {
    evt.preventDefault();
    setAddingAppointment(true);
    const formData = new FormData();
    const pictures = Array.from(picturesValue);
    let picturesResposne = null;

    const appointmentData = {
      title: titleValue,
      description: descrValue,
      date: dateValue,
      pictures: null,
    };

    if (pictures.length > 0) {
      try {
        pictures.forEach((image) => {
          formData.append("files", image);
        });
        picturesResposne = await uploadImages(formData);
        appointmentData.pictures = picturesResposne;
        const newAppointmentResponse = await createNewAppointment(
          appointmentData
        );
        const oldAppointments = state.appointments;
        await addAppointmentToUser(
          newAppointmentResponse.data,
          oldAppointments,
          id
        );
        setTimeout(() => {
          navigate(-1);
          setAddingAppointment(false);
        }, 2000);
      } catch (err) {
        setAddingAppointmentError(true);
        setTimeout(() => {
          setAddingAppointment(false);
        }, 2000);
      }
    } else {
      try {
        const newAppointmentResponse = await createNewAppointment(
          appointmentData
        );
        const oldAppointments = state.appointments;
        await addAppointmentToUser(
          newAppointmentResponse.data,
          oldAppointments,
          id
        );
        setTimeout(() => {
          navigate(-1);
          setAddingAppointment(false);
        }, 2000);
      } catch (err) {
        setAddingAppointmentError(true);
        setTimeout(() => {
          setAddingAppointment(false);
        }, 2000);
      }
    }
  };
  return (
    <>
      {addingAppointment ? (
        <Modal title="Tworzenie wizyty" animate />
      ) : (
        <NewAppointment
          titleValue={titleValue}
          titleIsTouched={titleIsTouched}
          titleError={titleError}
          titleBlurHandler={titleBlurHandler}
          titleValueHandler={titleValueHandler}
          descrValue={descrValue}
          descrIsTouched={descrIsTouched}
          descrError={descrError}
          descrBlurHandler={descrBlurHandler}
          descrValueHandler={descrValueHandler}
          dateValue={dateValue}
          dateIsTouched={dateIsTouched}
          dateError={dateError}
          dateBlurHandler={dateBlurHandler}
          dateValueHandler={dateValueHandler}
          picturesValue={picturesValue}
          picturesIsTouched={picturesIsTouched}
          picturesError={picturesError}
          picturesBlurHandler={picturesBlurHandler}
          picturesValueHandler={picturesValueHandler}
          newAppointmentHandler={newAppointmentHandler}
          addingAppointmentError={addingAppointmentError}
        />
      )}
    </>
  );
};
export default NewAppointmentContainer;
