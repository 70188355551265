import Appointments from "./Appointments";
import Modal from "../shared/Modal/Modal";
import { useParams } from "react-router-dom";
import {
  getAllAppointmentsOfPatient,
  deleteImage,
  deleteAppointment,
} from "../../services/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { PodologStoreCtx } from "../../store/store";
import Button from "../shared/Button/Button";

const AppointmentsContainer = () => {
  const [appointments, setAppointments] = useState();
  const [message, setMessage] = useState();
  const [fetching, setFetching] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [pictures, setPictures] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(PodologStoreCtx);
  const [deleteAppointmentQuestion, setDeleteAppointmentQuestion] =
    useState(false);
  const [deleteAppointmentError, setDeleteAppointmentError] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      try {
        let appointments = await getAllAppointmentsOfPatient(+id);
        appointments.data[0].attributes.appointments.data.sort((a, b) =>
          a.attributes.date < b.attributes.date ? 1 : -1
        );
        if (appointments.length < 1) {
          setMessage("Brak wizyt");
          setTimeout(() => setFetching(false), 2000);
        } else {
          setAppointments(appointments);
          dispatch({
            type: "addAppointments",
            payload: appointments.data[0].attributes.appointments,
          });
          setPictures(appointments.data[0].attributes.appointments.data);
          setTimeout(() => setFetching(false), 2000);
        }
      } catch (err) {
        setFetching(false);
      }
    };
    fetchData();
  }, [id, dispatch, update]);
  const newAppointmentRedirect = () => {
    navigate(`/patients/${id}/appointments/new`);
  };

  const pictureDetailsHandler = (details) => {
    dispatch({ type: "pictureDetails", payload: details });
  };

  const pictureDetailsCloseHandler = () => {
    dispatch({ type: "pictureDetailsClose" });
  };

  const editAppointmentHandler = (appointmentId) => {
    navigate(`/patients/${id}/appointments/${appointmentId}/edit`);
  };

  const deleteAppointmentQuestionHandler = (appointmentId) => {
    setPictures((prevState) =>
      prevState.filter((el) => el.id === appointmentId)
    );
    setAppointmentId(appointmentId);
    setDeleteAppointmentQuestion((prevState) => !prevState);
  };

  const deleteAppointmentHandler = async () => {
    setFetching(true);
    setDeleting(true);
    setDeleteAppointmentQuestion((prevState) => !prevState);
    try {
      if (
        pictures.length > 0 &&
        pictures !== null &&
        pictures[0].attributes.pictures.data !== null
      ) {
        let imageResponse = pictures[0].attributes.pictures.data.map(
          async (picture) => {
            await deleteImage(picture.id);
          }
        );
        if (imageResponse.error) {
          throw new Error("Nie udało się usunąć wizyty!");
        } else {
          let deleteAppointmentResponse = await deleteAppointment(
            appointmentId
          );
          if (deleteAppointmentResponse.error) {
            throw new Error("Nie udało się usunąć wizyty!");
          }
        }
      } else {
        let deleteAppointmentResponse = await deleteAppointment(appointmentId);
        if (deleteAppointmentResponse.error) {
          throw new Error("Nie udało się usunąć wizyty!");
        }
      }
      setTimeout(() => {
        setDeleting(false);
        setFetching(false);
        setDeleteAppointmentError(false);
      }, 1000);
      setUpdate((prevState) => !prevState);
    } catch (err) {
      setDeleteAppointmentError(true);
      setMessage(err.message);
      setDeleting(false);
      setFetching(false);
    }
  };
  return (
    <>
      {deleteAppointmentError && <h3>{message}</h3>}
      {fetching ? (
        <Modal
          title={deleting ? "Usuwanie wizyty" : "Pobieranie wizyt"}
          animate
        />
      ) : (
        <>
          {appointments && appointments.data.length > 0 ? (
            <Appointments
              appointments={appointments}
              message={message}
              newAppointmentRedirect={newAppointmentRedirect}
              pictureDetailsHandler={pictureDetailsHandler}
              pictureDetails={state.pictureDetails}
              pictureDetailsVisible={state.pictureDetailsVisible}
              pictureDetailsCloseHandler={pictureDetailsCloseHandler}
              editAppointmentHandler={editAppointmentHandler}
              deleteAppointmentQuestionHandler={
                deleteAppointmentQuestionHandler
              }
            />
          ) : (
            <>
              <h1>Brak wizyt!</h1>
              <Button
                text="Dodaj pierwszą wizytę pacjenta!"
                onClick={newAppointmentRedirect}
              />
            </>
          )}
        </>
      )}
      {deleteAppointmentQuestion && (
        <Modal ask>
          <div className="info">
            <h1>Czy na pewno chcesz usunąć wizytę?</h1>
            <p>
              Ta operacja spowoduje usunięcie wszystkich danych wizyty (włącznie
              ze zdjęciami).
            </p>
            <div className="buttons">
              <Button text="Usuń!" onClick={deleteAppointmentHandler} />
              <Button
                text="Anuluj"
                onClick={deleteAppointmentQuestionHandler}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default AppointmentsContainer;
