import Dashboard from "./Dashboard";
import { useNavigate } from "react-router-dom";

const DashboardContainer = () => {
  const navigate = useNavigate();
  const addNewPatient = () => {
    navigate("/patients/newPatient");
  };
  const patientsRedirect = () => {
    navigate("/patients");
  };
  return (
    <Dashboard
      newPatientRedirect={addNewPatient}
      patientsRedirect={patientsRedirect}
    />
  );
};
export default DashboardContainer;
