import Form from "../shared/Form/Form";
import styled from "styled-components";
import Button from "../shared/Button/Button";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  & h3 {
    &.error {
      color: red;
    }
    &.success {
      color: green;
    }
  }
`;

const AppointmentEdit = (props) => {
  return (
    <StyledContainer>
      <h1>Edycja wizyty</h1>
      {props.editingAppointmentError && (
        <h3 className="error">{props.message}</h3>
      )}
      {props.message && !props.editingAppointmentError && (
        <h3 className="success">{props.message}</h3>
      )}
      <Form onSubmit={props.editAppointmentHandler}>
        <div>
          <label>Tytuł:</label>
          <input
            type="text"
            value={props.titleValue}
            onChange={props.titleValueHandler}
            onBlur={props.titleBlurHandler}
            className={props.titleError && props.titleIsTouched && "error"}
          />
        </div>
        {props.titleError && props.titleIsTouched && (
          <p>Tytuł nie moze być pusty!</p>
        )}
        <div>
          <label>Opis:</label>
          <textarea
            rows="5"
            value={props.descrValue}
            onChange={props.descrValueHandler}
            onBlur={props.descrBlurHandler}
            className={props.descrError && props.descrIsTouched && "error"}
          />
        </div>
        {props.descrError && props.descrIsTouched && (
          <p>Opis nie moze być pusty!</p>
        )}
        <div>
          <label>Data:</label>
          <input
            type="datetime-local"
            value={props.dateValue}
            onChange={props.dateValueHandler}
            onBlur={props.dateBlurHandler}
            className={props.dateError && props.dateIsTouched && "error"}
          />
        </div>
        {props.dateError && props.dateIsTouched && <p>Wybierz datę!</p>}
        <div className="buttons">
          <Button
            text="Anuluj"
            decline
            onClick={props.editAppointmentDecline}
          />
          <Button text="Edytuj wizytę!" type="submit" />
        </div>
      </Form>
    </StyledContainer>
  );
};
export default AppointmentEdit;
