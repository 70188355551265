import styled from "styled-components";

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  & div {
    display: flex;
    width: 90%;
    padding: 1rem;
    & input {
      width: 60%;
      padding: 0.5rem;
      font-size: 1.1rem;
      &.error {
        background-color: salmon;
        border: 1px solid red;
      }
    }
    & label {
      width: 40%;
      padding: 0.5rem;
      font-size: 1.2rem;
      font-weight: 600;
    }
    & textarea {
      width: 60%;
      padding: 0.5rem;
      font-size: 1.1rem;
      &.error {
        background-color: salmon;
        border: 1px solid red;
      }
    }
    &.buttons {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  & p {
    width: 90%;
    text-align: right;
    color: red;
  }
`;

const Form = (props) => {
  return <StyledForm onSubmit={props.onSubmit}>{props.children}</StyledForm>;
};
export default Form;
