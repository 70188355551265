import { useEffect, useState } from "react";

const useInput = (validation, pictures = false, value = null) => {
  const [inputValue, setInputValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const isValid = validation(inputValue);
  const error = isTouched && !isValid;
  useEffect(() => {
    if (value !== null) {
      setInputValue(value);
    }
  }, [value]);

  const inputValueHandler = (evt) => {
    evt.preventDefault();
    if (pictures) {
      setInputValue(evt.target.files);
    } else {
      setInputValue(evt.target.value);
    }
  };
  const inputBlurHandler = (evt) => {
    evt.preventDefault();
    setIsTouched(true);
  };
  const reset = () => {
    setInputValue("");
    setIsTouched(false);
  };
  return {
    inputValue,
    isTouched,
    error,
    inputValueHandler,
    inputBlurHandler,
    reset,
  };
};
export default useInput;
