import styled from "styled-components";
import Button from "../shared/Button/Button";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 65vh;
  width: 100%;
`;
const Dashboard = (props) => {
  return (
    <StyledContainer>
      <Button text="Znajdź pacjenta" onClick={props.patientsRedirect} />
      <Button text="Dodaj nowego pacjenta" onClick={props.newPatientRedirect} />
    </StyledContainer>
  );
};
export default Dashboard;
