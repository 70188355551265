import { useEffect, useRef, useState } from "react";
import Patients from "./Patients";
import {
  deletePatient,
  deletePatientRelations,
  getAllPatients,
} from "../../services/api";
import { useContext } from "react";
import { PodologStoreCtx } from "../../store/store";
import Modal from "../shared/Modal/Modal";
import useInput from "../../hooks/use-input";
import { useNavigate } from "react-router-dom";
import Button from "../shared/Button/Button";

const PatientsContainer = () => {
  const { state, dispatch } = useContext(PodologStoreCtx);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState(null);
  const [deletePatientQuestion, setDeletePatientQuestion] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletingError, setDeletingError] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [patientAppointments, setPatientAppointments] = useState(null);
  const searchingRef = useRef(null);
  const navigate = useNavigate();

  const {
    inputValue: optionValue = "phone_number",
    inputValueHandler: optionValueHandler,
  } = useInput((input) => input !== "");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const patients = await getAllPatients();
        if (patients.data.length > 0) {
          dispatch({ type: "getAllPatients", payload: patients.data });
          setPatients(patients.data);
          setTimeout(() => setLoading(false), 2000);
        } else {
          dispatch({ type: "getAllPatients", payload: {} });
          setPatients(null);
          setLoading(false);
          throw new Error("Brak danych!");
        }
      } catch (err) {
        setMessage(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, deleting]);

  const searchingPatientHandler = () => {
    let searchedPatient = null;
    if (optionValue === "email") {
      searchedPatient = state.patients.filter((patient) =>
        patient.attributes.email.includes(searchingRef.current.value)
      );
    } else if (optionValue === "surname") {
      searchedPatient = state.patients.filter((patient) =>
        patient.attributes.surname.includes(searchingRef.current.value)
      );
    } else {
      searchedPatient = state.patients.filter((patient) =>
        patient.attributes.phone_number.includes(searchingRef.current.value)
      );
    }
    setPatients(searchedPatient);
  };

  const allAppointmentsRedirect = (patientId) => {
    navigate({
      pathname: `/patients/${patientId}/appointments`,
    });
  };

  const deletePatientHandler = async (patientId, appointments) => {
    setPatientId(patientId);
    setPatientAppointments(appointments);
    deletePatientQuestionHandler();
  };

  const deletePatientSubmit = async () => {
    setLoading(true);
    setDeleting(true);
    try {
      const deletePatientRelationsResponse = await deletePatientRelations(
        patientAppointments
      );
      if (deletePatientRelationsResponse) {
        await deletePatient(patientId);
        setTimeout(() => {
          setLoading(false);
          setDeleting(false);
          setDeletePatientQuestion(false);
        }, 2000);
      } else {
        setDeletingError(true);
      }
    } catch (err) {
      setDeletingError(true);
    }
  };

  const deletePatientQuestionHandler = () => {
    setDeletePatientQuestion((prevState) => !prevState);
  };

  const patientEditRedirect = (patientId) => {
    navigate(`/patients/${patientId}/edit`);
  };
  return (
    <>
      {loading ? (
        <Modal
          animate
          title={deleting ? "Usuwanie uzytkownika" : "Pobieranie danych"}
        />
      ) : (
        <>
          {patients ? (
            <Patients
              message={message}
              patients={patients}
              searchingRef={searchingRef}
              optionValue={optionValue}
              optionValueHandler={optionValueHandler}
              searchingPatient={searchingPatientHandler}
              allAppointmentsRedirect={(id) => allAppointmentsRedirect(id)}
              deletePatientHandler={deletePatientHandler}
              deletePatientQuestion={deletePatientQuestion}
              deletePatientQuestionHandler={deletePatientQuestionHandler}
              deletingError={deletingError}
              patientEditRedirect={patientEditRedirect}
            />
          ) : (
            <>
              <h1>Brak pacjentów</h1>
              <Button
                text="Dodaj pierwszego pacjenta!"
                onClick={() => navigate("/patients/newPatient")}
              />
            </>
          )}

          {deletePatientQuestion && (
            <Modal ask>
              <div className="info">
                <h1>Czy na pewno chcesz usunąć uzytkownika?</h1>
                <p>
                  Ta operacja spowoduje usunięcie wszystkich danych uzytkownika
                  w tym jego wizyt oraz całej dokumentacji (włącznie ze
                  zdjęciami).
                </p>
                <div className="buttons">
                  <Button text="Usuń!" onClick={deletePatientSubmit} />
                  <Button
                    text="Anuluj"
                    onClick={deletePatientQuestionHandler}
                  />
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};
export default PatientsContainer;
