import Button from "../shared/Button/Button";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const navigationHandler = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <h1>Nie znaleziono strony!</h1>
      <Button text="Powrót na stronę główną" onClick={navigationHandler} />
    </>
  );
};
export default NotFound;
