import Form from "../shared/Form/Form";
import styled from "styled-components";
import Button from "../shared/Button/Button";

const StyledConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  & h3 {
    color: red;
  }
`;

const NewAppointment = (props) => {
  return (
    <StyledConatiner>
      <h1>Nowa wizyta</h1>
      {props.addingAppointmentError && <h3>Nie udało się utworzyć wizyty!</h3>}
      <Form onSubmit={props.newAppointmentHandler} id="new-appointment">
        <div>
          <label>Tytuł:</label>
          <input
            type="text"
            value={props.titleValue}
            onChange={props.titleValueHandler}
            onBlur={props.titleBlurHandler}
            className={props.titleError && props.titleIsTouched && "error"}
          />
        </div>
        {props.titleError && props.titleIsTouched && (
          <p>Tytuł nie moze być pusty!</p>
        )}
        <div>
          <label>Opis:</label>
          <textarea
            rows="5"
            value={props.descrValue}
            onChange={props.descrValueHandler}
            onBlur={props.descrBlurHandler}
            className={props.descrError && props.descrIsTouched && "error"}
          />
        </div>
        {props.descrError && props.descrIsTouched && (
          <p>Opis nie moze być pusty!</p>
        )}
        <div>
          <label>Data:</label>
          <input
            type="datetime-local"
            value={props.dateValue}
            onChange={props.dateValueHandler}
            onBlur={props.dateBlurHandler}
            className={props.dateError && props.dateIsTouched && "error"}
          />
        </div>
        {props.dateError && props.dateIsTouched && <p>Wybierz datę!</p>}
        <div>
          <label>Zdjęcia (opcjonalnie):</label>
          <input
            type="file"
            multiple
            onChange={props.picturesValueHandler}
            onBlur={props.picturesBlurHandler}
          />
        </div>
        <div className="buttons">
          <Button text="Anuluj" decline />
          <Button text="Dodaj wizytę!" type="submit" />
        </div>
      </Form>
    </StyledConatiner>
  );
};
export default NewAppointment;
