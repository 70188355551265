import styled from "styled-components";
import Button from "../shared/Button/Button";
import Form from "../shared/Form/Form";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  & h3 {
    color: red;
  }
`;

const LoginView = (props) => {
  return (
    <StyledContainer>
      <h1>Logowanie</h1>
      {props.errorLogin && <h3>Nieprawidłowy adres e-mail lub hasło!</h3>}
      <Form onSubmit={props.submitFormHandler}>
        <div className={props.emailIsTouched && props.emailError && "error"}>
          <label>Adres e-mail:</label>
          <input
            type="text"
            value={props.emailValue}
            onChange={props.emailValueHandler}
            onBlur={props.emailBlurHandler}
            className={props.emailIsTouched && props.emailError && "error"}
          />
        </div>
        {props.emailIsTouched && props.emailError && (
          <p>Nieprawidłowy adres e-mail!</p>
        )}
        <div
          className={props.passwordIsTouched && props.passwordError && "error"}
        >
          <label>Hasło:</label>
          <input
            type="password"
            value={props.passwordValue}
            onChange={props.passwordValueHandler}
            onBlur={props.passwordBlurHandler}
            className={
              props.passwordIsTouched && props.passwordError && "error"
            }
          />
        </div>
        {props.passwordIsTouched && props.passwordError && (
          <p>Nieprawidłowe hasło!</p>
        )}
        <Button type="submit" text="Login!" disabled={props.disabled} />
      </Form>
    </StyledContainer>
  );
};
export default LoginView;
