import { createContext, useReducer } from "react";

export const PodologStoreCtx = createContext();

const podologReducer = (state, action) => {
  switch (action.type) {
    case "login": {
      localStorage.setItem("isLogged", JSON.stringify(true));
      localStorage.setItem("credentials", JSON.stringify(action.payload));
      return { ...state, isLogged: true, credentials: action.payload };
    }
    case "logout": {
      localStorage.removeItem("isLogged");
      localStorage.removeItem("credentials");
      return { ...state, isLogged: false, credentials: {} };
    }
    case "getAllPatients": {
      return { ...state, patients: action.payload };
    }
    case "pictureDetails": {
      return {
        ...state,
        pictureDetails: action.payload,
        pictureDetailsVisible: !state.pictureDetailsVisible,
      };
    }
    case "pictureDetailsClose": {
      return {
        ...state,
        pictureDetails: {},
        pictureDetailsVisible: !state.pictureDetailsVisible,
      };
    }
    case "addAppointments": {
      return { ...state, appointments: action.payload };
    }
    default: {
      return state;
    }
  }
};

const PodologProvider = ({ children }) => {
  let initialValue = null;
  if (localStorage.getItem("credentials") && localStorage.getItem("isLogged")) {
    initialValue = {
      isLogged: JSON.parse(localStorage.getItem("isLogged")),
      credentials: JSON.parse(localStorage.getItem("credentials")),
    };
  } else {
    initialValue = {
      isLogged: false,
      credentials: {},
    };
  }

  const [state, dispatch] = useReducer(podologReducer, {
    isLogged: initialValue.isLogged,
    credentials: initialValue.credentials,
    appointments: {},
    patients: {},
    pictureDetails: {},
    pictureDetailsVisible: false,
  });
  const value = { state, dispatch };

  return (
    <PodologStoreCtx.Provider value={value}>
      {children}
    </PodologStoreCtx.Provider>
  );
};
export { PodologProvider };
