import styled, { css } from "styled-components";

const StyledButton = styled.button`
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: 0.3s;

  ${(props) =>
    props.disabled
      ? css`
          background-color: grey;
          cursor: not-allowed;
        `
      : css`
          &:hover {
            tranform: scale(1.1);
            cursor: pointer;
            background: rgb(213, 119, 181);
            color: white;
            border-color: white;
          }
        `}
  ${(props) =>
    props.decline &&
    css`
      &:hover {
        background-color: red;
        color: white;
      }
    `}
`;
const Button = (props) => {
  return (
    <StyledButton
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      decline={props.decline}
    >
      {props.text}
    </StyledButton>
  );
};
export default Button;
