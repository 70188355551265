import Card from "../shared/Card/Card";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  & div.searching {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    width: 90%;
    & input {
      padding: 0.5rem;
      width: 60%;
    }
    & select {
      padding: 0.5rem;
    }
  }
  & h1.error {
    color: red;
  }
`;

const Patients = (props) => {
  return (
    <StyledContainer>
      <h1>Pacjenci</h1>
      <div className="searching">
        <input
          type="text"
          ref={props.searchingRef}
          onChange={props.searchingPatient}
          placeholder="Wyszukaj pacjenta"
        />
        <select value={props.optionValue} onChange={props.optionValueHandler}>
          <option value="phone_number">Numer telefonu</option>
          <option value="email">Adres email</option>
          <option value="surname">Nazwisko</option>
        </select>
      </div>
      {props.patients && props.patients.length > 0 ? (
        props.patients.map((patient, index) => {
          return (
            <Card
              key={index}
              name={patient.attributes.name}
              surname={patient.attributes.surname}
              email={patient.attributes.email}
              phone_number={patient.attributes.phone_number}
              allAppointmentsRedirect={() =>
                props.allAppointmentsRedirect(patient.id)
              }
              deletePatient={() =>
                props.deletePatientHandler(
                  patient.id,
                  patient.attributes.appointments
                )
              }
              editPatient={() => props.patientEditRedirect(patient.id)}
              margin
            />
          );
        })
      ) : (
        <p>Brak danych</p>
      )}
      {props.deletingError && (
        <h1 className="error">Nie udało się usunąć uzytkownika!</h1>
      )}
    </StyledContainer>
  );
};
export default Patients;
