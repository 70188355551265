export const login = async (props) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/auth/local`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: props.email,
        password: props.password,
      }),
    }
  );
  return await response.json();
};

export const addNewPatient = async (props) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const data = {
    email: props.email,
    phone_number: props.phone_number,
    name: props.name,
    surname: props.surname,
  };
  const exists = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/patients?filters[email][$eq]=${props.email}`,
    {
      filters: {
        email: {
          $eq: props.email,
        },
      },
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
      },
    }
  );
  const existsData = await exists.json();
  if (existsData.data.length < 1) {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/patients`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt.jwt}`,
        },
        body: JSON.stringify({ data: data }),
      }
    );
    return await response.json();
  } else {
    throw new Error("Pacjent o tym adresie e-mail juz istnieje!");
  }
};

export const getAllPatients = async () => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const patients = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/patients?populate=appointments,appointments.pictures`,
    {
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
      },
    }
  );
  return await patients.json();
};

export const getPatient = async (patientId) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const patient = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/patients/${patientId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
      },
    }
  );
  return await patient.json();
};

export const editPatient = async (patientData, patientId) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const data = {
    email: patientData.email,
    phone_number: patientData.phone_number,
    name: patientData.name,
    surname: patientData.surname,
  };
  const editPatientResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/patients/${patientId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt.jwt}`,
      },
      body: JSON.stringify({ data: data }),
    }
  );
  return editPatientResponse.json();
};

export const getAllAppointmentsOfPatient = async (patientId) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const appoitments = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/patients?populate=appointments,appointments.pictures&filters[id][$eq]=${patientId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
      },
    }
  );
  return await appoitments.json();
};

export const uploadImages = async (images) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const imagesUpload = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
      },
      body: images,
    }
  );
  return await imagesUpload.json();
};

export const deleteImage = async (imageId) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const imageDeleteResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/upload/files/${imageId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
      },
    }
  );
  return await imageDeleteResponse.json();
};

export const getAppointment = async (appointmentId) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const appointment = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/appointment/${appointmentId}?populate=pictures`,
    {
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
      },
    }
  );
  return appointment.json();
};

export const createNewAppointment = async (appointment) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const data = {
    title: appointment.title,
    description: appointment.description,
    date: appointment.date,
    pictures: appointment.pictures,
  };
  const newAppointment = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/appointment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt.jwt}`,
      },
      body: JSON.stringify({ data: data }),
    }
  );
  return await newAppointment.json();
};

export const deleteAppointment = async (appointmentId) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const deleteAppointment = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/appointment/${appointmentId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt.jwt}`,
      },
    }
  );
  return await deleteAppointment.json();
};

export const editAppointment = async (appointmentId, appointmentData) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const updateAppointment = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/appointment/${appointmentId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt.jwt}`,
      },
      body: JSON.stringify({ data: appointmentData }),
    }
  );
  return updateAppointment.json();
};

export const addAppointmentToUser = async (
  newAppointment,
  appointments,
  patientId
) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const update = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/patients/${patientId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          appointments:
            Object.keys(appointments).length === 0
              ? [newAppointment.id]
              : [...appointments.data, newAppointment.id],
        },
      }),
    }
  );
  return await update.json();
};

export const deletePatientRelations = async (appointments) => {
  try {
    appointments.data.map(async (appointment) => {
      if (appointment.attributes.pictures.data !== null) {
        appointment.attributes.pictures.data.map(async (picture) => {
          await deleteImage(picture.id);
        });
      }
      await deleteAppointment(appointment.id);
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const deletePatient = async (patientId) => {
  const jwt = JSON.parse(localStorage.getItem("credentials"));
  const deletePatientResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/patients/${patientId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwt.jwt}`,
        "Content-Type": "application/json",
      },
    }
  );
  return deletePatientResponse.json();
};
