import styled from "styled-components";
import Form from "../shared/Form/Form";
import Button from "../shared/Button/Button";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  & h3 {
    &.error {
      color: red;
    }
    &.success {
      color: green;
    }
  }
`;

const NewPatient = (props) => {
  return (
    <StyledContainer>
      <Form onSubmit={props.onSubmit}>
        <h1>Nowy Pacjent</h1>
        {props.addPatientError && <h3 className="error">{props.message}</h3>}
        {props.message && !props.addPatientError && (
          <h3 className="success">{props.message}</h3>
        )}
        <div>
          <label>Imię:</label>
          <input
            type="text"
            value={props.nameValue}
            onChange={props.nameValueHandler}
            onBlur={props.nameBlurHandler}
            className={props.nameIsTouched && props.nameError && "error"}
            placeholder="Jan"
          />
        </div>
        {props.nameIsTouched && props.nameError && (
          <p>Imię nie moze być puste!</p>
        )}
        <div>
          <label>Nazwisko:</label>
          <input
            type="text"
            value={props.surnameValue}
            onChange={props.surnameValueHandler}
            onBlur={props.surnameBlurHandler}
            className={props.surnameIsTouched && props.surnameError && "error"}
            placeholder="Kowalski"
          />
        </div>
        {props.surnameIsTouched && props.surnameError && (
          <p>Nazwisko nie moze być puste!</p>
        )}
        <div>
          <label>Adres e-mail:</label>
          <input
            type="text"
            value={props.emailValue}
            onChange={props.emailValueHandler}
            onBlur={props.emailBlurHandler}
            className={props.emailIsTouched && props.emailError && "error"}
            placeholder="adres@email.com"
          />
        </div>
        {props.emailIsTouched && props.emailError && (
          <p>Nieprawdiłowy adres e-mail!</p>
        )}
        <div>
          <label>Numer telefonu:</label>
          <input
            type="text"
            value={props.phoneValue}
            onChange={props.phoneValueHandler}
            onBlur={props.phoneBlurHandler}
            className={props.phoneIsTouched && props.phoneError && "error"}
            placeholder="+48 666 666 666"
          />
        </div>
        {props.phoneIsTouched && props.phoneError && (
          <p>Nieprawdiłowy numer telefonu!</p>
        )}
        <div className="buttons">
          <Button text="Anuluj" decline onClick={props.declineHandler} />
          <Button text="Dodaj pacjenta!" disabled={props.disabled} />
        </div>
      </Form>
    </StyledContainer>
  );
};
export default NewPatient;
