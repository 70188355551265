import styled, { keyframes, css } from "styled-components";

const animation = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 65vh;
  ${(props) =>
    props.animate &&
    css`
      & div.animation {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20rem;
        &:after {
          content: " ";
          display: block;
          width: 64px;
          height: 64px;
          margin: 8px;
          border-radius: 50%;
          border: 6px solid #fff;
          border-color: rgb(213, 119, 181) transparent rgb(213, 119, 181)
            transparent;
          animation: ${animation} 1.2s linear infinite;
        }
      }
    `}
  ${(props) =>
    props.ask &&
    css`
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7);
      & div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 60%;
        background: white;
        padding: 2rem;
        border: 1px solid black;
        border-radius: 10px;
        & .buttons {
          border: none;
          flex-direction: row;
          width: 50%;
          justify-content: space-around;
        }
      }
    `}
`;

const Modal = (props) => {
  return (
    <StyledContainer animate={props.animate} ask={props.ask}>
      <h1>{props.title}</h1>
      {props.animate && <div className="animation"></div>}
      {props.children}
    </StyledContainer>
  );
};
export default Modal;
