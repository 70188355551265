import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  transition: 0.3s;
  padding: 0.5rem;
  &:hover {
    transform: scale(1.1);
  }
  &.active {
    border-bottom: 1px solid white;
  }
`;
const ActiveLink = (props) => {
  return (
    <StyledNavLink
      to={props.to}
      className={({ isActive }) => (isActive ? "active" : null)}
    >
      {props.children}
    </StyledNavLink>
  );
};
export default ActiveLink;
