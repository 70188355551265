import styled from "styled-components";
import Button from "../Button/Button";

const StyledContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  & div.picture {
    height: 70vh;
    width: 80%;
    object-fit: contain;
    padding: 2rem;
    box-sizing: border-box;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const PictureDetailsCard = (props) => {
  return (
    <StyledContainer>
      <div className="picture">
        <img src={props.src} alt={props.alt} />
      </div>
      <Button text="Anuluj" onClick={props.closePictureDetails} />
    </StyledContainer>
  );
};
export default PictureDetailsCard;
