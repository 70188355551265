import Card from "../shared/Card/Card";
import styled from "styled-components";
import Button from "../shared/Button/Button";
import PictureDetailsCard from "../shared/Card/PictureDetailsCard";

const StyledConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  & div.appointment-title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    margin-bottom: 2rem;
  }
`;

const Appointments = (props) => {
  return (
    <StyledConatiner>
      <div className="appointment-title">
        <h1>
          Wizyty pacjenta{" "}
          <i>
            {props.appointments.data[0].attributes.name}{" "}
            {props.appointments.data[0].attributes.surname}
          </i>
        </h1>
        <Button text="Nowa wizyta" onClick={props.newAppointmentRedirect} />
      </div>
      {props.appointments.data[0].attributes.appointments.data.length > 0 ? (
        props.appointments.data[0].attributes.appointments.data.map(
          (appointment, index) => (
            <Card
              key={index}
              title={appointment.attributes.title}
              date={new Date(appointment.attributes.date).toLocaleString(
                "pl-PL"
              )}
              description={appointment.attributes.description}
              editAppointment={() =>
                props.editAppointmentHandler(appointment.id)
              }
              deleteAppointment={() =>
                props.deleteAppointmentQuestionHandler(appointment.id)
              }
              appointment
            >
              {appointment.attributes.pictures.data !== null ? (
                <div className="pictures">
                  {appointment.attributes.pictures.data.map(
                    (picture, index) => (
                      <div key={index}>
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}${picture.attributes.url}`}
                          alt={picture.attributes.alternativeText}
                          onClick={() =>
                            props.pictureDetailsHandler({
                              url: `${process.env.REACT_APP_BACKEND_URL}${picture.attributes.url}`,
                              alt: picture.attributes.alternativeText,
                            })
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </Card>
          )
        )
      ) : (
        <h1>Brak wizyt!</h1>
      )}
      {props.pictureDetailsVisible && (
        <PictureDetailsCard
          src={props.pictureDetails.url}
          alt={props.pictureDetails.alt}
          closePictureDetails={props.pictureDetailsCloseHandler}
        />
      )}
    </StyledConatiner>
  );
};
export default Appointments;
